<template>
  <div>
    <v-progress-linear v-if="loading" :indeterminate="true"/>
    <v-dialog v-model="showExportDialog" persistent>
      <v-card>
        <v-card-actions>
          <v-btn v-if="exportStatus.errors" outlined @click="showErrorsInDialog=!showErrorsInDialog">Show/Hide Errors
            ({{ exportStatus.errors.length }})
          </v-btn>
          <v-spacer/>
          <v-btn outlined :loading="exporting" @click="exportStatus={}; showExportDialog=false">Close</v-btn>
        </v-card-actions>
        <v-card-title v-if="exporting">Exporting</v-card-title>
        <v-card-title v-if="!exporting">Exporting Completed</v-card-title>
        <v-card-text>
          <span v-if="exporting"> {{ exportStatus.tasksCompleted }} of {{ exportStatus.tasksToComplete }}</span>
          <span v-if="!exporting">{{ exportStatus.tasksToComplete }} deliveries exported</span>
          <v-list dense v-if="showErrorsInDialog">
            <v-list-item v-for="(e,index) of (exportStatus.errors||[])" v-bind:key="index">
              <v-list-item-content>
                <v-list-item-title>
                  <v-alert color="warning" outlined>
                    {{ e.onfleetPayload && e.onfleetPayload.address }} :
                    {{ e.errorMessage }}
                  </v-alert>
                </v-list-item-title>
                <v-list-item-subtitle>
                  {{ formatOnfleetPayload(e.onfleetPayload) }}
                </v-list-item-subtitle>
              </v-list-item-content>
            </v-list-item>
          </v-list>
        </v-card-text>
        <v-card-actions>
          <v-btn v-if="exportStatus.errors" outlined @click="showErrorsInDialog=!showErrorsInDialog">Show/Hide Errors
            ({{ exportStatus.errors.length }})
          </v-btn>
          <v-spacer/>
          <v-btn outlined :loading="exporting" @click="exportStatus={}; showExportDialog=false">Close</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <v-container class="orders" fluid v-if="!loading">
      <v-footer fixed class="d-print-none pl-10 pr-8" v-if="datesFormatted">
        {{ datesFormatted }}
        <v-spacer/>
        {{ totalCount }} meals
        {{ orders.length }} orders
        {{ Object.keys(groupOrdersBy(ordersFiltered, 'address')).length }} deliveries
      </v-footer>
      <v-alert color="red" v-if="isAdmin">Danger Danger! Admin mode</v-alert>
      <v-toolbar flat class="d-print-none pb-10">
        <ProductionSelector
          :dates.sync="dates"
          :production.sync="production"
          v-on:update="fetchData"
        />
        <!--        <v-text-field :value="datesFormatted"-->
        <!--                      label="Select dates"-->
        <!--                      single-line-->
        <!--                      hide-details-->
        <!--                      readonly-->
        <!--                      @click="showDatePicker=true"-->
        <!--                      append-icon="mdi-calendar"-->
        <!--        />-->
        <!--        <v-spacer/>-->
        <!--        <v-text-field-->
        <!--          label="Search"-->
        <!--          v-model="search"-->
        <!--          single-line-->
        <!--          hide-details-->
        <!--          clearable-->
        <!--          append-icon="mdi-magnify"-->
        <!--        />-->
        <v-spacer/>
        <v-select
          label="Filter by driver"
          v-model="driverSelected"
          :items="drivers"
          single-line
          hide-details
          clearable
          style="width: 20px"
        />
        <v-select
          class="pl-2"
          label="Location"
          flat
          hide-details
          v-model="filterLocation"
          :items="['','home','office']"
          style="width: 20px"
        />
        <v-checkbox
          class="pl-2 pt-2"
          label="UID"
          v-model="showUid"
          hide-details
        />
        <v-dialog
          v-model="showDatePicker"
          ref="dialog"
          width="290px"
          persistent
        >
          <v-date-picker
            v-model="datePickerDate"
            :range="isAdmin"
            no-title
            @close="!isAdmin && closeDatePicker()"
            @change="closeDatePicker"
          ></v-date-picker>
          <v-btn v-if="isAdmin" @click="closeDatePicker">Close</v-btn>
        </v-dialog>
      </v-toolbar>
      <template v-if="!exporting">
        <template v-if="!readOnly">
          <v-dialog v-model="showLinkDialog" max-width="600px">
            <v-card>
              <v-card-title>Link Task</v-card-title>
              <v-card-text>
                <p>
                  This will create a link between an onfleet task (created in onfleet) and a FED delivery, so that ETA,
                  driver assignment, SMS, etc, are
                  all work correctly. You should not need to use this; the export button on this page should be enough.
                </p>
                <p>Address to link: <b>{{ linkOrders && linkOrders.address }}</b></p>
                <v-select
                  label="Onfleet Task"
                  v-model="onfleetShortId"
                  :items="onfleetTasks"
                  :item-value="t => t.shortId"
                  :item-text="t => t.customer ? `${t.shortId} - ${formatAddress(t.customer.address)}` : JSON.stringify(t)"
                  clearable
                  class="mr-2"
                  hint="Something like this: d588f4d4"
                />
                <p><b>Important:</b> for an onfleet task to appear in the dropdown above, it must be assigned to a
                  driver.</p>
                <p> Selected onfleet task: {{ onfleetShortId }} </p>
                <p> Fed order references: {{ linkOrderRefs.join(', ') }}</p>
                <!--              <pre>{{linkOrders && linkOrders.addressOrders}}</pre>-->
              </v-card-text>
              <v-card-actions>
                <v-btn @click="showLinkDialog=false">Cancel</v-btn>
                <v-spacer/>
                <v-btn @click="linkTask(linkOrderRefs,onfleetShortId)">Link</v-btn>
              </v-card-actions>
            </v-card>
          </v-dialog>
          <v-toolbar flat class="d-print-none">
            <v-btn outlined class="ml-2" :loading="exporting" @click="startExportAll">
              Export to Onfleet
            </v-btn>
            <v-checkbox label="Show Errors" class="ml-2" v-model="onlyErrors" hide-details/>
            <v-checkbox label="Show Not Exported" class="ml-2" v-model="notExported" hide-details/>
            <v-btn v-if="isAdmin" x-small color="error" outlined class="ml-2" :loading="exporting"
                   @click="resetOnfleetStatus">
              Reset Onfleet Status
            </v-btn>
            <v-btn color="green" outlined class="ml-2" :loading="exporting" @click="checkOnfleetStatus()">
              Check Onfleet Status
            </v-btn>
            <v-spacer/>
            <v-btn :loading="generatingPDF" outlined class="ml-2" @click="downloadPDF($event.target)">Download PDF
            </v-btn>
          </v-toolbar>
        </template>
        <template v-if="groupBy==='deliveries'">
          <v-card
            style="page-break-after: always;"
            flat
            v-for="driver of drivers.filter(d => driverSelected?d===driverSelected:true)"
            v-bind:key="driver"
          >
            <template v-if="Object.keys(groupOrdersBy(orders.filter(o => o.driver === driver), 'address')).length>0">
              <v-card-title>
                Driver: {{ driver }}
              </v-card-title>
              <!--            <pre>{{ orders.filter(o => o.driver === driver) }}</pre>-->
              <v-row
                style="page-break-inside: avoid;"
                v-for="(addressOrders,address) of groupOrdersBy(orders.filter(o => o.driver === driver), 'address')"
                v-bind:key="address"
              >
                <v-col cols="2" class="text-center" align-self="center" style="font-size: 40px">
                  <div class="d-print-none">
                    <v-btn
                      v-if="!isExported(addressOrders) && !isOnfleetTask(addressOrders)"
                      small
                      class="mb-2 mr-4"
                      :loading="exporting"
                      @click="exportToOnfleet2({address,orders:addressOrders})"
                      :color="isExported(addressOrders)?'green':'primary'"
                      :disabled="isExported(addressOrders)"
                    >
                      Export
                    </v-btn>
                    <v-btn
                      small
                      outlined
                      class="mb-2 mr-4"
                      :loading="exporting"
                      @click="checkOnfleetStatus(addressOrders)"
                      v-if="isExported(addressOrders)"
                      color="green"
                    >
                      Check
                    </v-btn>
                    <v-btn
                      small
                      outlined
                      class="mb-2 mr-4"
                      :loading="exporting"
                      @click="showLinkDialog=true;linkOrders={address,addressOrders}"
                      v-if="!isExported(addressOrders) && !isOnfleetTask(addressOrders)"
                      color="primary"
                    >
                      Link
                    </v-btn>

                    <v-chip x-small class="" outlined
                            color="green"
                            v-if="isExported(addressOrders)">
                      {{ getOnfleetIds(addressOrders).join(', ') }}
                    </v-chip>
                  </div>
                  <input class="d-none d-print-block" type="checkbox" style="width: 50px; height: 50px;">
                </v-col>
                <v-col cols="">
                  {{ address }}
                  <div class="text-caption d-print-none">Deliver between {{ getEarliestTime(addressOrders) }} and
                    {{ getLatestTime(addressOrders) }} on {{ dateFormatted(addressOrders[0].ship_date) }}
                  </div>
                  <div v-for="(customerOrders,customerName) of groupOrdersBy(addressOrders,'name')"
                       v-bind:key="customerName">
                    <div>
                      <span class="font-weight-bold d-print">{{ customerName }}</span>
                      <v-btn
                        v-if="customerOrders[0].uid"
                        :to="{name:'Customer',params:{id:customerOrders[0].uid}}"
                        x-small outlined
                        class="d-print-none">
                        edit
                      </v-btn>

                      <br/>
                      <div class="d-print-none caption">
                        QRCodes: {{ getQrCodes(customerName, customerOrders).join(', ') }}
                      </div>
                      <!--                    <span v-if="isFirstOrder(customerOrders[0])">😃-->
                      <!--                      <input type="checkbox" style="width: 20px; height: 20px;"/>-->
                      <!--                    </span>-->
                    </div>
                    <!--                  <span v-if="addressOrders[0].eta">-->
                    <!--                    @ {{ dateFormatted(customerOrders[0].eta, {formatString: 'h:mma'}) }}-->
                    <!--                  </span>-->
                    <div style="font-size: small"
                         v-if="getDeliveryInstruction(customerOrders[0].customer,customerOrders[0].date)">
                      <b>Note:</b> {{ getDeliveryInstruction(customerOrders[0].customer, customerOrders[0].date) }}
                    </div>
                    <!--                  {{getDeliveryStatus(customerOrders)}}-->
                    <div v-for="(s,index) of getDeliveryStatus(customerOrders)" v-bind:key="index">
                      <v-alert type="error" v-if="s.error">{{ s.error }}</v-alert>
                    </div>
                  </div>
                </v-col>
              </v-row>
            </template>
          </v-card>
        </template>
        <!-- blank gap at the bottom to avoid fixed footer hiding data-table -->
      </template>
      <div class="ma-8"></div>
    </v-container>
  </div>
</template>


<script>
import {mapActions, mapGetters} from 'vuex'
import moment from 'moment';
import {
  dateFormatted,
  escapeRegex,
  formatAddress,
  getAddress,
  getDateString,
  getDeliveryInstruction,
  getEarliestDefaultDeliveryTime,
  getLatestDefaultDeliveryTime,
  getProductionDays,
} from '@/store/utils'
import api from '@/api';
import BagCalc from "@/store/bag-calc";
import {range} from "lodash";
import urlState from "@/router/urlState";
import ProductionSelector from "@/components/ProductionSelector.vue";
import diff from "microdiff";


export default {
  components: {ProductionSelector},
  mixins: [urlState],
  data() {
    return {
      units: ['g', 'kg'],
      decimalPlaces: [1, 2],
      streamsSelected: [],
      driverSelected: null,
      selected: [],
      missing_meals: new Set(),
      missing_count: {},
      showDatePicker: false,
      dates: [],
      search: '',
      loading: false,
      post: null,
      error: null,
      multiview: false,
      multiviewMealSelected: {},
      headers: [
        // {
        //   text: 'Email',
        //   align: 'start',
        //   value: 'email',
        //   width: '*'
        // },
        {text: 'Driver', value: 'driver'},
        {text: 'Customer Name', value: 'name',},
        {text: 'Quantity', value: 'quantity'},
        // {text: 'Meal ID', value: 'meal_id'},
        {text: 'Meal', value: 'mealName'},
        {text: 'Stream', value: 'stream'},
        {text: 'Allergy/Restrictions', value: 'displayAllergies',},

        {text: 'Time of day', value: 'tod'},
        {text: 'Date', value: 'date'}

      ],
      showMissing: false,
      filteredIngredients: [],
      maxEmailPerLink: 450,
      groupBy: 'deliveries',
      showAssignInProgress: null,
      showClearInProgress: null,
      datePickerDate: null,
      filterLocation: null,
      exporting: null,
      overrideShipDate: '',
      onlyErrors: false,
      showUid: false,
      showExportDialog: null,
      exportStatus: {errors: []},
      onfleetShortId: '',
      showLinkDialog: false,
      driverTasks: [],
      generatingPDF: false,
      showErrorsInDialog: null,
      notExported: null,
      linkOrders: null,
      production: null,
      currentData: null,
      ordersFetched: [],
      fetchCustomerPromise: null,
      customersRaw: null
    }
  },
  mounted() {
    this.syncToUrl({
      param: 'filterLocation', urlParam: 'location', initFromRoute: true,
      // parseCallback: (v) => v === 'true'
    });
    this.syncToUrl({
      param: 'search', urlParam: 'search', initFromRoute: true,
      // parseCallback: (v) => v === 'true'
    });
    this.syncToUrl({
      param: 'dates', urlParam: 'dates', initFromRoute: true,
      parseCallback: (v) => Array.isArray(v) ? v : [v]
    });
    this.syncToUrl({
      param: 'production', urlParam: 'production', initFromRoute: true,
    });
    this.syncToUrl({
      param: 'driverSelected', urlParam: 'driver', initFromRoute: true,
      // parseCallback: (v) => v === 'true'
    });
    this.syncToUrl({
      param: 'groupBy', urlParam: 'group', initFromRoute: true,
      // parseCallback: (v) => v === 'true'
    });
    this.syncToUrl({
      param: 'onlyErrors', urlParam: 'errors', initFromRoute: true,
      parseCallback: (v) => v === 'true'
    });

    this.setDates();
    this.fetchData();
  },
  watch: {
    showDatePicker(v) {
      if (v) {
        if (this.isAdmin) {
          this.datePickerDate = this.dates;
        } else {
          this.datePickerDate = this.dates[0];
        }
      } else {
        if (this.isAdmin) {
          this.dates = this.datePickerDate;
        } else {
          this.dates = getProductionDays(this.datePickerDate)
        }
      }
    },
  },
  computed: {
    ...mapGetters([
      'meals',
      'diets',
      'getMeal',
      'getMealIngredients',
      'getDietName',
      'getDietStream',
      'getTimeOfDay',
      'getSummaryForDiet',
    ]),
    // showWarning(){
    //   if (this.da)
    // },
    readOnly() {
      return !!this.$route.meta.readOnly;
    },
    linkOrderRefs() {
      const orderRefs = this.linkOrders
        ? [...new Set(
          this.linkOrders.addressOrders
            .filter(o => !!o.lineItemRef)
            .map(o => `${o.order_id}/logistics/${moment(o.ship_date).format('ddd').toLowerCase()}`))]
        : [];
      return orderRefs;
    },
    isFiltered() {
      const allOrders = this.ordersUnfiltered.length;
      const displayedOrders = this.ordersFiltered.length;
      console.log('is fitlered', {allOrders, displayedOrders});
      return allOrders !== displayedOrders;
    },
    ordersByAddress() {
      // console.log('orders', this.orders);
      const result = {};
      this.orders.forEach(o => {
        const {customer} = o;
        const address = getAddress(o.customer, o.date);
        const display = [customer.first_name, customer.last_name, address.unit || '', address.line1, address.postal_code].join(' ');
        result[o.uid] = display
      })
      return Object.entries(result)
        .map(([key, value]) => ({key, value}));
    },

    ordersUnfiltered() {
      const orders = this.ordersFetched;
      // console.log('concat orders from dates', {dates: this.dates});

      // orders.forEach(o => o.name = `${o.last_name}, ${o.first_name}`);
      orders.forEach(o => o.name = `${o.first_name} ${o.last_name}`);
      // orders.forEach(o => o.mealName = this.getMeal(o.meal_id) && this.getMeal(o.meal_id).name);
      orders.forEach(o => o.customer = this.getCustomer2(o.uid));
      orders.forEach(o => o.email = o.customer ? o.customer.email : '');
      orders.forEach(o => o.driver = o.driver || 'Unassigned');

      const ship_dates = [...new Set(orders.map(o => o.ship_date))];
      // console.log('ship_dates', ship_dates);
      this.driverTasks.forEach(dt => {
        const task = dt.task;
        // const worker = dt.worker;
        const ship_date = moment(task.eta).format(moment.HTML5_FMT.DATE);
        // console.log('checking task ship date',ship_date,dt)
        if (!ship_dates.includes(ship_date)) {
          // console.log('ship date excluded',ship_date, task.eta, worker.name)
        } else {
          const taskAddress = task.address;
          const address = {
            unit: taskAddress.apartment,
            state: 'British Columbia',
            postal_code: taskAddress.postalCode, //: 'V6A 2M9',
            line1: `${taskAddress.number} ${taskAddress.street}`,
            city: taskAddress.city, // : 'Vancouver',
            country: taskAddress.country, //: 'Canada'
            delivery_instruction: task.notes
          }
          const fauxOrder = {
            isOnfleetTask: true,
            shortId: task.shortId,
            eta: task.eta,
            name: dt.task.name + ' (onfleet)',
            driver: dt.worker.name,
            address,
            customer: {
              address,
            },
            quantity: 1
          };
          console.log('adding faux order', fauxOrder);
          orders.push(fauxOrder);
        }
      })

      orders.forEach(o => {
        if (!o.address) {
          console.warn('order is missing address!', o);
          o.address = {};
        }
      })
      return orders;
    },
    onfleetTasks() {
      return this.ordersUnfiltered.filter(o => o.isOnfleetTask);
    },
    ordersFiltered() {
      let orders = this.ordersUnfiltered;

      console.log('orders fitler', orders);
      // filter out the 'use_shipping' customers
      orders = orders.filter(o => {
        const address = getAddress(o.customer, o.date);
        if (!address) {
          console.log('no address', o);
          return true;
        }
        return !address.use_shipping;
      });

      // console.log('orders', orders);
      // const streamsSelected = this.streamsSelected;
      if (this.driverSelected) {
        orders = orders.filter(o => o.driver === this.driverSelected);
      }

      if (this.onlyErrors) {
        orders = orders.filter(o => {
          const [deliveryStatus = {}] = this.getDeliveryStatus([o]);
          return !!deliveryStatus.error;
        });
      }

      if (this.notExported) {
        orders = orders.filter(o => !this.isExported([o]));
      }


      if (this.search) {
        const regex = new RegExp(escapeRegex(this.search), 'i');
        orders = orders.filter(o => [o.name, formatAddress(getAddress(o.customer, o.date))].join('').match(regex))
      }

      if (this.filterLocation) {
        orders = orders.filter(o => getAddress(o.customer, o.date).location === this.filterLocation);
      }
      return orders;
    },
    orders() {
      // used to do fruit cup hack here
      return this.ordersFiltered;
    },
    totalCount() {
      let count = 0;
      this.orders.forEach(o => count += o.quantity);
      return count;
    },
    mealsByDiet() {
      console.log('this.getMealsByDiet(this.date)', this.getMealsByDiet(this.date));
      return this.getMealsByDiet(this.date);
    },
    summary() {
      return this.$store.getters.summary(this.date);
    },
    drivers() {
      const drivers = new Set();
      this.ordersUnfiltered.forEach(o => drivers.add(o.driver));
      console.log('this.driverTasks', this.driverTasks);
      this.driverTasks.forEach(dt => drivers.add(dt.worker.name));
      const sortedDrivers = [...drivers.values()].filter(d => !!d).sort();
      console.log('drivers', sortedDrivers);
      return sortedDrivers;
    },
    datesFormatted() {
      const format = 'dddd MMMM D';
      if (!this.dateFrom) {
        return '';
      } else if (this.dateFrom === this.dateTo) {
        return `${moment(this.dateFrom).format(format)}`;
      } else {
        return `${moment(this.dateFrom).format(format)} - ${moment(this.dateTo).format(format)}`
      }
    },
    dateFrom() {
      return [...this.dates].sort()[0];
    },
    dateTo() {
      return [...this.dates].sort().reverse()[0];
    },
    shipments() {
      let {orders} = this;
      orders = orders.filter(o => getAddress(o.customer, o.date).use_shipping);
      return this.groupOrdersBy(orders, 'uid');
    },
    bags() {
      let {orders, driverSelected, drivers} = this;
      if (driverSelected) {
        orders = orders.filter(o => o.driver === driverSelected);
        drivers = [driverSelected];
      } else {
        drivers = ['Unassigned', ...drivers.filter(d => d !== 'Unassigned')]
      }
      const result = [];
      for (const driver of drivers) {
        const {driverCounts, customerLabels} = BagCalc.computeBags(
          this.groupOrdersBy(orders.filter(o => o.driver === driver))
        );
        result.push({
          isDriverLabel: true,
          driver: driver,
          count: driverCounts[driver]
        })
        result.push(...customerLabels.filter(c => c.driver === driver));
      }
      return result;
    },
    selectedUids() {
      return [...new Set(this.ordersFiltered.map(o => o.uid))];
    },
    filename() {
      return `driver-sheets-${this.dates.join()}-${this.driverSelected ? this.driverSelected : 'all'}.pdf`;
    }
  },
  methods: {
    ...mapActions([
      'fetchDiets',
      'reload'
    ]),
    getDeliveryInstruction,
    range,
    isOnfleetTask(orders) {
      return orders.some(o => o.isOnfleetTask);
    },
    getBagCount(orders) {
      return BagCalc.getNumberOfBags(orders);
    },
    setDates() {
      const dates = this.dates;
      console.log('set dates', dates.length, dates);
      if (this.isAdmin) {
        // this.dates = dates;
      } else {
        if (dates.length === 1) {
          this.dates = getProductionDays(dates[0]);
        }
      }
      console.log('set dates', this.dates);
    },
    getDatesInRange() {
      const dates = [];
      if (!this.dateFrom || !this.dateTo) {
        return dates;
      }
      const from = moment(this.dateFrom);
      const to = moment(this.dateTo);

      const diff = moment.duration(to.diff(from));
      if (diff.asDays() >= 0 && diff.asDays() <= 31) {
        // console.log('adding days ', {from, to})
        while (from.isSameOrBefore(to)) {
          const dateString = getDateString(from);
          // console.log('added date', dateString);
          dates.push(dateString);
          from.add(1, 'day');
        }
      } else {
        window.alert(diff.asDays() + ' days is above the limit of 7');
      }
      // console.log('getDatesInRange', dates);
      return dates;
    },
    async refetchData() {
      return this.fetchData();
    },
    async fetchData() {
      this.error = this.post = null
      const {dates, production} = this;
      if (!dates || dates.length === 0) {
        console.log('no dates, no fetch');
        this.loading = false;
        return;
      }
      if (!production) {
        console.log('no production, no fetch');
        this.loading = false;
        return;
      }

      if (this.currentData && diff(this.currentData, {dates, production}).length === 0) {
        console.log('already loaded', this.currentData);
        return
      } else {
        console.log('fetching prep', {dates, production});
        this.currentData = {dates, production};
      }

      this.loading = true;
      const params = {
        from: this.dateFrom,
        to: this.dateTo,
        productions: [this.production],
        // properties: ['quantity', 'allergyDetected', 'allergies', 'meal_id', 'stream', 'tod', 'meal_size'],
      };
      console.log('fetching ', params);
      this.ordersFetched = await api
        .get('v2/order/search', {
          params
        })
        .then(({data}) => data);

      const userIds = [...new Set(this.ordersFetched.map(o => o.uid))];
      this.customersRaw = await api
        .post('v2/customers', {
          userIds,
        })
        .then(({data}) => Object.fromEntries(data.map(u => [u.uid, u])));

      return Promise.all([
        this.fetchDriverTasks(),
      ])
        .catch((e) => {
          console.error('something went wrong', e);
        })
        .finally(() => {
          this.loading = false;
        });
    },
    closeDatePicker() {
      this.showDatePicker = false;
      this.$nextTick(() => this.fetchData());
    },
    dateFormatted,
    groupOrdersBy(orders, prop = 'name') {
      const result = {};
      const keyMap = {};
      orders.forEach(o => {
        let key;
        if (prop === 'address') {
          const address = o.address;
          key = formatAddress(address);
        }
        if (prop === 'name') {
          let name = o.name.trim();
          key = this.showUid ? (name + '-' + o.uid) : name;
        }
        if (prop === 'uid') {
          key = o.uid;
        }

        if (key) {
          const keyUpper = key.toUpperCase();
          keyMap[keyUpper] = keyMap[keyUpper] || key;
          result[keyUpper] = result[keyUpper] || [];
          result[keyUpper].push(o);
        }
      });
      const sortedKeys = Object
        .keys(result)
        .sort((a, b) => {
          const [orderA] = result[a];
          const [orderB] = result[b];
          if (orderA && orderA.eta && orderB && orderB.eta) {
            return orderA.eta - orderB.eta
          } else {
            // console.log('keymap', a, keyMap[a], keyMap);
            return keyMap[a].localeCompare(keyMap[b]);
          }
        });
      const sortedResult = {};
      sortedKeys.forEach(key => sortedResult[keyMap[key]] = result[key]);
      return sortedResult;
    },
    isFirstOrder(customerOrder) {
      return customerOrder && customerOrder.isFirstOrder;
    },
    async resetOnfleetStatus() {
      if (confirm('this will erase the export status in orders allowing you to do a clean re-export.  if you have not deleted all tasks in onfleet you will end up with duplicates')) {
        console.log('reset all');
        this.exporting = true;
        const exportStatus = {errors: []}
        this.exportStatus = exportStatus;
        const result = await api.post('/v2/onfleet/reset-status', {orders: this.orders})
          .catch(e => console.error('failed', e));
        console.log('reset result', result.data);
        window.alert(`reset finished!

${JSON.stringify(result.data)}

click ok to reload the page.
`);
        await this.refetchData();
      }
      this.exporting = false;
    },
    async checkOnfleetStatus(orders) {
      const confirmAllMessage = `
NOTE: THIS TAKES A LONG TIME IF THERE ARE HUNDREDS OF TASKS!

This will check and update the export status (ensure onfleet task exists and order state here is correct).

This allows you to do a clean re-export if some tasks appear exported here but aren't showing up in onfleet.
      `;
      if (orders || confirm(confirmAllMessage)) {

        // unless passed a set of order run check on all orders
        if (!orders) {
          orders = this.orders;
        }
        console.log('reset all');
        this.exporting = true;
        const exportStatus = {errors: []}
        this.exportStatus = exportStatus;
        const count = {};
        const ordersByUid = this.groupOrdersBy(orders, 'uid');
        for (const uid of Object.keys(ordersByUid)) {
          const result = await api
            .post('/v2/onfleet/check-status', {orders: ordersByUid[uid]})
            .catch(e => console.error('failed', e));
          const data = result.data;
          console.log('reset result ' + uid, data);
          const uidCount = data.count || {};
          for (const k of Object.keys(uidCount)) {
            count[k] = count[k] || 0;
            count[k] += uidCount[k];
          }
        }
        const isChanged = count.fixed > 0;
        const msg = [
          count.deleted > 0 && `${count.deleted} orders had their onfleet status deleted.`,
          count.skipped > 0 && `${count.skipped} orders did not have an onfleet task, so they were not checked (skipped).`,
          count.fixed > 0 && `${count.fixed} orders had an onfleet task that has been deleted, so the onfleet reference was removed.`,
          count.checked > 0 && `${count.checked} orders were checked and the onfleet task existed, so all good!`
        ]
          .filter(s => !!s)
          .join('\n');
        if (isChanged) {
          window.alert(`check finished!  changed made

${msg}

click ok to reload the page.
`);

        } else {
          window.alert(`check finished.  no changes made.

${msg}

`);
        }
      }

      await this.refetchData();
      this.exporting = false;
    },
    async exportAllToOnfleet2() {
      if (this.exporting) {
        return false;
      }
      const firstDate = this.dates.sort()[0];
      if (moment().add(1, 'day').isSameOrAfter(firstDate)) {
        if (!confirm('WARNING: you are running an export on tasks that should already have been delivered.  Bad things will happen!\n\n\nARE YOU SURE?!!?..')) {
          return;
        }
      }

      console.log('export all');
      this.exporting = true;
      this.showExportDialog = true;
      const exportStatus = {errors: []};
      this.exportStatus = exportStatus;
      // const ordersByAddress = this.groupOrdersBy(this.orders.filter(o => o.driver === this.driver), 'address');
      const ordersByAddress = this.groupOrdersBy(this.orders, 'address');
      exportStatus.tasksCompleted = 0;
      exportStatus.tasksToComplete = Object.keys(ordersByAddress).length;
      for (const [address, orders] of Object.entries(ordersByAddress)) {
        if (this.isExported(orders)) {
          console.log('already exported', address);
        } else {
          try {
            console.log('exporting', address, orders);
            await this.exportToOnfleet2({address, orders, exportAll: true});
          } catch (e) {
            console.error('failed', e);
          }
        }
        exportStatus.tasksCompleted++;
      }

      await this.refetchData();
      this.exporting = false;
    },
    async exportToOnfleet2({orders, exportAll = false}) {
      if (this.isOnfleetTask(orders)) {
        console.log('onfleet task, do not export', orders);
        return;
      }
      const exportOne = !exportAll;
      const shipDates = [...new Set(orders.map(o => o.ship_date))];
      if (shipDates.length > 1) {
        if (!confirm('orders contain more than 1 ship date: ' + shipDates.join(', ') + '\n'
          + 'Only the first date will be used! ' + shipDates[0])) {
          return;
        }
      }

      if (exportOne) {
        this.exporting = true;
      }

      const earliest = this.getEarliestTime(orders);
      const latest = this.getLatestTime(orders);

      const uids = [...new Set(orders.map(o => o.uid))];
      const customers = uids.map(uid => this.getCustomer2(uid));

      const instructions = orders
        .map(o => getDeliveryInstruction(o.customer, o.date))
        .map(s => s.trim ? s.trim() : '');

      const addresses = orders
        .map(o => formatAddress(getAddress(this.getCustomer2(o.uid), o.date), {excludeUnit: true}));
      const units = orders
        .map(o => getAddress(this.getCustomer2(o.uid), o.date).unit);


      const address = addresses[0];
      const unit = units[0];

      const notes = [...new Set(instructions)].join(', \n');

      const names = [...new Set(customers.map(c => [c.first_name, c.last_name].join(' ')))];
      if (names.length > 1) {
        names.unshift(`${names.length} PEOPLE`);
      }
      const name = names.join(' |\n ');

      const ordersByCustomer = this.groupOrdersBy(orders, 'name');
      const qrCodes = [];
      for (const customerName of Object.keys(ordersByCustomer)) {
        qrCodes.push(...this.getQrCodes(customerName, ordersByCustomer[customerName]));
      }

      const mainAccountCustomer = customers.find(c => !c.mainAccount);
      let phone_number;
      if (mainAccountCustomer) {
        phone_number = mainAccountCustomer.phone_number;
      } else {
        phone_number = customers[0].phone_number;
      }


      const payload = {
        name,
        phone_number,
        quantity: 1,  // TODO: until bag count is accurate, force to 1
        // this is a bit more complex since customer meals aren't combined in bags
        // quantity : Object.values(this.groupOrdersBy(orders, 'name'))
        //   .reduce((sum, orders) => sum + this.getBagCount(orders), 0),
        shipDate: shipDates[0],
        earliest,
        latest,
        unit,
        address,
        notes,
        orders,
        qrCodes
      };

      if (this.overrideShipDate) {
        payload.forceShipDate = this.overrideShipDate;
      }

      if (exportOne) {
        this.showExportDialog = true;
      }

      console.log('onfleet create', payload)
      const result = await api.post('v2/onfleet/create/', payload)
        .catch(e => console.error('fail', e))

      if (result) {
        const {data} = result;
        if (data && data.error) {
          console.error('recoding error', data.error)
          if (!this.exportStatus.errors) {
            this.$set(this.exportStatus, 'errors', []);
          }
          const onfleetPayload = {...payload};
          delete onfleetPayload.orders;
          this.exportStatus.errors.push({uids, errorMessage: data.error, onfleetPayload});
        }
        console.log('result', data);
      }

      if (exportOne) {
        await this.refetchData();
        this.exporting = false;
      }
    },
    getCustomerLocations(customerOrders) {
      return [...new Set(customerOrders.map(o => getAddress(o.customer, o.date).location))];
    },
    getCustomerDeliveryDates(customerOrders) {
      return [...new Set(customerOrders.map(o => o.ship_date))];
    },
    getCustomerStreams(customerOrders) {
      return [...new Set(customerOrders.map(o => o.stream))];
    },
    getDeliveryStatus(customerOrders) {
      const logistics = customerOrders[0].logistics;
      if (!logistics) {
        return [];
      }
      /** FIXME: this isn't really great - shipments should not be saved inside each order record
       * we presume that the order line item record contains all the shipments of the week (not just
       * the day a particular line item is on */
      const ship_dates = [...new Set(customerOrders.map(o => o.ship_date))]
        .map(date => moment(date).format('ddd').toLowerCase());
      const filtered = logistics
        .filter(s => ship_dates.includes(s.day))
      if (filtered.length > 0) {
        return filtered;
      } else {
        return logistics;
      }
    },
    formatOnfleetPayload(payload) {
      return payload && payload.recipients
        ? payload.recipients.map(({name, notes, phone}) => `${name} (${phone}): ${notes}`).join(', ')
        : '';
    },
    getCustomerName(uid) {
      const c = this.getCustomer2(uid);
      return `${c.first_name} ${c.last_name}`
    },
    formatAddress,
    copyToClipboard(text) {
      try {
        navigator.clipboard.writeText(text);
      } catch (e) {
        alert('sorry, copy not supported on this device: ' + e.message)
      }
    },
    linkTask(orderRefs, onfleetShortId) {
      const payload = {
        orderRefs,
        onfleetShortCode: onfleetShortId
      };
      console.log('payload', payload);

      api
        .post('v2/onfleet/link', payload)
        .then(r => {
          console.log('done', r);
          alert('task has been linked.  eta and driver assignment will be updated in ~5 mins');
          this.showLinkDialog = false;
          this.linkOrders = null;
          this.onfleetShortId = '';
        })
        .catch(e => console.error('fail', e));
    },
    uniqueUids(orders) {
      return [...new Set(orders.map(o => o.uid))];
    },
    getEarliestTime(orders) {
      const times = [...new Set(orders.map(o => {
        const address = o.address;
        const earliestTime = address.earliest_time;
        if (earliestTime) {
          return earliestTime;
        } else {
          return getEarliestDefaultDeliveryTime(address, o.ship_date);
        }
      }))]
        .filter(t => !!t)
        .sort()
        .reverse();
      return times[0];
    },
    getLatestTime(orders) {
      const times = [...new Set(orders.map(o => {
        const address = o.address; //getAddress(o.customer, o.date);
        const latestTime = address.latest_time;
        if (latestTime) {
          return latestTime
        } else {
          return getLatestDefaultDeliveryTime(address, o.ship_date);
        }
      }))]
        .filter(t => !!t)
        .sort();
      return times[0];
    },
    fetchDriverTasks() {
      return api.get('v2/onfleet/driver-tasks')
        .then(({data}) => {
          console.log('tasks', data);
          this.driverTasks = data;
        });
    },
    async downloadPDF(element) {
      const payload = [];
      this.generatingPDF = true;
      const drivers = this.driverSelected ? [this.driverSelected] : this.drivers;
      for (const driver of drivers) {
        const ordersByAddress = this.groupOrdersBy(this.orders.filter(o => o.driver === driver), 'address');
        let runs = 1;
        let rows = [];
        let driverPayload = {
          driver,
          rows,
          date: this.dateFrom,
        };
        for (const [address, orders] of Object.entries(ordersByAddress)) {
          driverPayload.eta = driverPayload.eta || moment(orders[0].eta).format('LT');
          try {
            // console.log('pdfing', address, orders);
            const ordersByName = this.groupOrdersBy(orders, 'name');

            const instructionMap = {};
            for (const [name, customerOrders] of Object.entries(ordersByName)) {
              const note = this.getDeliveryInstruction(customerOrders[0].customer, customerOrders[0].date)
              instructionMap[note] = name;
            }

            const names = [];
            for (const [name, customerOrders] of Object.entries(ordersByName)) {
              // console.log('customerOrders', customerOrders);
              const note = this.getDeliveryInstruction(customerOrders[0].customer, customerOrders[0].date)
              if (instructionMap[note] === name) {
                names.push({name, note})
              } else {
                names.push({name, note: ''})
              }
            }

            rows.push({address, names});

            if (names.length === 1 && names[0].name == "Fed (onfleet)") {
              // start a new run
              driverPayload.driver = `${driver} ${runs++}`;
              payload.push(driverPayload);
              console.log('starting a new sheet', driverPayload);
              rows = [];
              driverPayload = {
                driver: `${driver} ${runs}`,
                date: this.dateFrom,
                rows,
              }
            } else {
              // console.log('not new ' + names[0].name, names);
            }
          } catch (e) {
            console.error('failed', e);
          }
        }
        if (rows.length > 0) {
          payload.push(driverPayload);
        }
      }

      try {
        console.log('sending pdf request', payload);
        const response = await api.post('v2/render-pdf/driver', {drivers: payload}, {responseType: 'blob'});
        const blob = new Blob([response.data], {type: response.headers['content-type']});
        const link = document.createElement('a');
        if (element) {
          // this prevents the page from jumping when the click event happens
          // element.parentElement.appendChild(link);
        }

        link.href = URL.createObjectURL(blob);
        link.download = this.filename;
        link.click();
        URL.revokeObjectURL(link.href);
        this.generatingPDF = false;
        return true;
      } catch (e) {
        this.generatingPDF = false;
        console.error('failed', e);
        return false;
      }


    },
    getOnfleetIds(orders) {
      return [...new Set(orders.map(o => o.deliveryInfo && o.deliveryInfo.data && o.deliveryInfo.data.shortId))].filter(s => !!s);
    },
    isExported(orders) {
      return this.getOnfleetIds(orders).length > 0;
    },

    getQrCodes(customerName, orders) {
      return BagCalc.generateQRValues(customerName, {orders});
    },
    startExportAll() {
      console.log('start export');
      this.exportAllToOnfleet2().catch(e => console.warn('fail', e));
    },
    fetchCustomers2() {
      if (!this.fetchCustomerPromise) {
        this.fetchCustomerPromise = api
          .get('v2/customers', {
            params: {
              hasMeals: true
            }
          })
          .then(({data}) => {
            console.log('got customers', data);
            this.customersRaw = Object.fromEntries(data.map(user => [user.uid, user]));
          })
          .finally(() => {
            this.fetchCustomerPromise = false;
          });
      }

      return this.fetchCustomerPromise;
    },
    getCustomer2(uid) {
      if (!this.customersRaw[uid]) {
        console.log('could not find uid', uid, this.customersRaw)
      }
      return this.customersRaw[uid];
    }
  },
  props: {
    isAdmin: {type: Boolean, default: false, required: false},

  }
}
</script>
